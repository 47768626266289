import { SVGProps } from "react";
interface Colors {
  colors?: Array<string>;
}

export default function Close() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.58581 8.50002L1.22185 14.864C0.831324 15.2545 0.831324 15.8877 1.22185 16.2782C1.61237 16.6687 2.24554 16.6687 2.63606 16.2782L9.00002 9.91424L15.364 16.2782C15.7545 16.6687 16.3877 16.6687 16.7782 16.2782C17.1687 15.8877 17.1687 15.2545 16.7782 14.864L10.4142 8.50002L16.7782 2.13606C17.1687 1.74554 17.1687 1.11237 16.7782 0.721848C16.3877 0.331324 15.7545 0.331324 15.364 0.721848L9.00002 7.08581L2.63606 0.721848C2.24554 0.331324 1.61237 0.331324 1.22185 0.721848C0.831324 1.11237 0.831324 1.74554 1.22185 2.13606L7.58581 8.50002Z"
        fill="black"
        fillOpacity="0.65"
      />
    </svg>
  );
}
