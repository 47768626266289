import classNames from "classnames";
import Image from "next/image";
import hotToast from "react-hot-toast";

interface IToast {
  type: "success" | "error" | "warning";
  title: string;
  message: string;
}

const ICON = {
  error: "/alertCircle.svg",
  warning: "/warningTriangle.svg",
  success: "/checkCircle.svg",
};

export const toast = ({ type, title, message }: IToast) => {
  const bgColor = classNames({
    " bg-[#F2F8F2] border border-[#0E8610] text-[#076908]": type === "success",
    " bg-[#FFF2F0] border border-[#DD2D0E] text-[#A92A14]": type === "error",
    " bg-warning-100 border border-warning-600 text-warning-700": type === "warning",
  });

  hotToast.custom(
    t => (
      <div
        id="toast"
        className={`flex inline-flex justify-between ${bgColor} px-3 py-3 my-2 rounded max-w-[34rem]`}
        role="alert"
      >
        <div className="flex">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 rounded-lg">
            <Image className="" src={ICON[type]} alt={ICON[type]} width={20} height={20} />
          </div>
          <div className="ms-2 text-base font-normal">
            <span className="font-bold">{title}</span>
            <div className="mb-1 text-base font-normal">{message}</div>
          </div>
        </div>
      </div>
    ),
    { position: "top-right" },
  );
};
