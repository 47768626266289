import Footer from "@src/components/common/footer";
import Layout from "@src/components/common/Layout";
import Loading from "@src/components/common/Loading";
import ReportNotAvailable from "@src/components/powerBiEmbed/ReportNotAvailable";
import { useGetUserReportsQuery, useLazyGetReportQuery } from "@src/services/reports";
import { IReports } from "@src/shared/interfaces/reports.interface";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { NextPageWithLayout } from "../_app";

const PowerBiEmbed = dynamic(() => import("@src/components/powerBiEmbed"), { ssr: false });

interface ReportProps {
  reports: IReports[];
}

const Report: NextPageWithLayout<ReportProps> = ({ reports }) => {
  const router = useRouter();
  const { slugs } = router.query;
  const [workspaceId, externalId] = Array.isArray(slugs) ? slugs : [];

  const [triggerReport, { isLoading, data, isFetching, isError }] = useLazyGetReportQuery();
  const { data: userReportsData } = useGetUserReportsQuery({});
  const { token, reportFilters, reportPages } = data || {};

  const [hasAccess, setHasAccess] = useState<boolean | undefined>(undefined);
  const [reportIsActive, setReportIsActive] = useState(true);
  const [reportTitle, setReportTitle] = useState<string | null>(null);

  useEffect(() => {
    if (!workspaceId && !externalId) return;
    triggerReport({ workspaceId, externalId });
  }, [workspaceId, externalId, triggerReport]);

  useEffect(() => {
    if (!userReportsData || !userReportsData.reports || !Array.isArray(userReportsData.reports)) {
      return;
    }

    const userReports = userReportsData.reports;
    const access = userReports.some((report: IReports) => report.externalId === externalId);

    if (access !== hasAccess) setHasAccess(access);

    const activeReport = userReports.find((report: IReports) => report.externalId === externalId);
    setReportIsActive(activeReport?.isActive ?? true);

    if (activeReport && activeReport.title && activeReport.isActive) {
      setReportTitle(`Relatório | ${activeReport.title}`);
    } else {
      setReportTitle("Relatório Indisponível");
    }
  }, [userReportsData, externalId, hasAccess]);

  const pageTitle = reportTitle || "Carregando Relatórios";

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      {isLoading || isFetching || hasAccess === undefined ? (
        <div className="w-full h-screen relative flex justify-center items-center">
          <Loading color="fill-neutral-900" />
        </div>
      ) : (
        <>
          {hasAccess && reportIsActive ? (
            <PowerBiEmbed
              externalId={externalId}
              token={token}
              filters={reportFilters}
              activePages={reportPages}
            />
          ) : (
            <>
              <ReportNotAvailable />
              <div className="bottom-0 absolute">
                <Footer />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

Report.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

export default Report;
