export const ACCESS_HOME_PAGE = "AcessoHomePage";
export const ACCESS_SUMMARY_OBJECT = "AcessoResumoObjeto";
export const ACCESS_REQUEST_NEW_SOLUTIONS = "AcessoSolicitacaoNovaSolucao";
export const CLICK_NEW_SOLUTIONS = "QueroNovaSolução";
export const CLICK_ACCESS = "CliqueAcessar";
export const CLICK_ANALYZE_CONTENT = "CliqueAnalisarConteudo";
export const CLICK_BOX_PRIVACY = "CliqueCheckboxPrivacidade";
export const CLICK_CONTENT_APPROVAL = "CliqueAprovacaoConteudo";
export const CLICK_DOWNLOAD_CONTENT = "CliqueDownloadConteudo";
export const CLICK_HISTORY = "CliqueVerHistorico";
export const CLICK_LEAVE_FOR_LATER = "CliqueDeixarParaDepois";
export const CLICK_SEND_FEEDBACK = "CliqueEnviarFeedback";
export const CLICK_VIEW_TEXT_REJECT = "CliqueVisualizarTextoRejeicao";
export const CLOSE_APPROVED_CONTENT = "FecharAprovaçãoConteudo";
export const CLOSE_NEW_SOLUTION = "FecharNovaSolução";
export const UPDATE_PASSWORD = "AlteracaoSenha";
export const LOGIN = "Login";
export const PRIVACY_PAGE = "PaginaPrivacidade";
export const CLICK_RESET_PASSWORD = "CliqueResetSenha";
export const RECOVER_PASSWORD = "RecuperarSenha";
export const SEND_NEW_SOLUTION = "EnviarNovaSolucao";
export const SESSION_NAVIGATION = "SessaoNavegacao";
export const OPTION_EMAIL = "OptionEmail";
export const X_NEW_SOLUTION = "XNovaSolução";
export const WRITTING = "EventEscrita";
export const PBI_EVENT = "pbi_event";
export const LOGOUT = "Logoff";
